'use strict';

import './jquery-global.js';
import './jquery.mask.min.js';
import foundation from 'foundation-sites';
import jquery_ui from 'jquery-ui/ui/widgets/datepicker';
import jquery_ui_ru from 'jquery-ui/ui/i18n/datepicker-ru';

$(() => {
    let $mainMenu = $('#main-menu');
    let $register = $('#register-block');
    let $search = $('#search-block');
    let $datePiker = $( ".profile-date-piker");
    let $editForm = $(".fos_user_profile_edit");
    let isLarge = () => {
        return matchMedia(Foundation.media_queries['large']).matches;
    };
    let $profile_list = $('.interest-list');
    let $search_select = $('#search-select');
    $(document).foundation();

    if ($editForm.length) {
        $.validate({
            lang: 'ru'
        });
    }
    $datePiker.mask('00.00.0000', {placeholder: "__.__.____"});

    $('#register_button, #search_button, #main_menu_button').on('click', (e) => {
        e.preventDefault();
        if ($register.is(":visible")) {
            $register.foundation('toggle');
        }
        if ($search.is(":visible")) {
            $search.foundation('toggle');
        }
        if (!isLarge && $mainMenu.is(":visible")) {
            $mainMenu.foundation('toggle');
        }
    });

    $(".toggle-button-link").click((e) => {
        e.preventDefault();

        $(e.currentTarget).toggleClass("open");
    });

    $(".set-avatar").click((e) => {
        e.preventDefault();
        let $input = $(e.currentTarget).next('input');

        $input.trigger('click');
    });

    if ($search_select.length) {
        $search_select.change((e) => {
            let value = $(e.target).val();

            if (value != '') {
                window.location.href = $(e.target).val();
            }
        });
    }
    if ($profile_list.length) {
        let $interests = $profile_list.find(".interest");
        $interests.on('click', (e) => {
            $(e.target).toggleClass("selected");
            let $input = $(e.target).find('input');
            if ($input.length) {
                if ($input.hasClass('selectAll')) {
                    let $allInputs = $interests.find('input');
                    if ($input.is(':checked')) {
                        $interests.removeClass("selected");
                        $allInputs.removeAttr('checked');
                    } else {
                        $interests.addClass("selected");
                        $allInputs.attr('checked', 'checked');
                    }
                } else {
                    $input.trigger('click');
                }
            }
        });
    }

    $('body').on('click', '.loading-block a', (e) => {
        let $this = $(e.currentTarget);
        let $loader = $this.parent();
        let url = $this.attr('href');
        let $spinner = $loader.find('.spinner');
        $this.hide();
        $spinner.show();

        $.ajax({
            url: url,
            method: 'POST',
            cache: "false",
            dataType: "html",
        }).done((data) => {
            $loader.remove();
            $('#post_list').append(data);
        });
        e.preventDefault();
    });
});
